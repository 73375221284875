import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/solidgear/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const SafetyClassTableStyled = styled.div`
  padding-top: ${spacing.medium};
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-top: calc(${spacing.medium} + ${spacing.small});
  }
`;

export const TableContainer = styled.div``;

export const SafetyClass = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TableRow = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.gray4};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:last-of-type {
    border-bottom: none;
  }
  p {
    font-size: ${fontSizes.bodyS};
    line-height: 18px;
    margin: ${spacing.small};
  }
`;

interface ColumnTitle {
  $color: CSSProperties['color'];
}
export const ColumnTitle = styled(Paragraph)<ColumnTitle>`
  color: ${({ $color }) => $color};
  margin: 0;
  margin-bottom: ${spacing.small};
  width: 48px;
  text-align: center;
`;

export const DotsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Dot {
  $active?: boolean;
  $color: CSSProperties['color'];
  $exists: boolean;
}

export const Dot = styled.div<Dot>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0 20px;
  opacity: ${({ $active }) => ($active ? 1 : 0.2)};
  background-color: ${({ $color }) => $color};
  visibility: ${({ $exists }) => ($exists ? 'visible' : 'hidden')} !important;
`;
