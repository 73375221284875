import { useId } from 'react';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import {
  SafetyClassFragment,
  SafetyClassTableBlockFragment,
  SafetyClassValue,
} from '@hultafors/solidgear/types';

import { Paragraph } from '../paragraph/paragraph';
import { SafetyClassIcon } from '../SafetyClassIcon/SafetyClassIcon';
import { SafetyClassTable } from '../SafetyClassTable/SafetyClassTable';
import { TextLink } from '../text-link/text-link';

import {
  SafetyClassIconWrapper,
  SafetyClassTableBlockStyled,
  SafetyClassTableHeader,
  TextContent,
} from './safety-class-table-block.styled';

interface SafetyClassTableBlockProps extends SafetyClassTableBlockFragment {
  singleSafetyClass?: string;
}

export const SafetyClassTableBlock: React.FC<SafetyClassTableBlockProps> = ({
  title,
  description,
  linkText,
  linkUrl,
  includedSafetyClasses,
  classes,
  singleSafetyClass,
  campaignClass,
}) => {
  const uid = useId();
  const { settings, isEnabled } = useGlobal();

  if (!classes.length || !isEnabled('safetyClassTable')) {
    return null;
  }

  function safetyClassMapper(safetyClass: SafetyClassFragment, _i: number) {
    if (!safetyClass.name) {
      return null;
    }

    return (
      <SafetyClassIcon
        safetyClass={safetyClass.name as SafetyClassValue}
        filled={true}
        key={`SafetyClass-${uid}-${safetyClass.name}`}
      />
    );
  }

  return (
    <SafetyClassTableBlockStyled>
      <SafetyClassTableHeader>
        <SafetyClassIconWrapper id="safety_class">
          {campaignClass?.name
            ? (
              <SafetyClassIcon
                safetyClass={campaignClass.name as SafetyClassValue}
                filled={true}
              />
              )
            : !singleSafetyClass
                ? (
                    includedSafetyClasses.map(safetyClassMapper)
                  )
                : singleSafetyClass
                  ? (
                    <SafetyClassIcon
                      safetyClass={singleSafetyClass as SafetyClassValue}
                      filled={true}
                    />
                    )
                  : null}
        </SafetyClassIconWrapper>
        <TextContent>
          <h2>{title}</h2>
          <Paragraph>{description}</Paragraph>
          {linkUrl && linkText && (
            <TextLink
              className="TextLink"
              iconUrl="/assets/gfx/arrow-right.svg"
              href={createUrl(linkUrl, settings)}
            >
              {linkText}
            </TextLink>
          )}
        </TextContent>
      </SafetyClassTableHeader>
      {classes.length > 0 && (
        <SafetyClassTable
          safetyClassTable={classes}
          includedSafetyClasses={includedSafetyClasses}
          singleSafetyClass={singleSafetyClass}
        />
      )}
    </SafetyClassTableBlockStyled>
  );
};
