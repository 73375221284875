import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const SafetyClassTableBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.gray4};
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.medium};
  margin-inline: auto;
  max-width: 1200px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${spacing.large};
    gap: 50px;
  }
`;

export const SafetyClassTableHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;

  h2 {
    font-style: normal;
    font-family: ${fontFamilies.fontRegularBold};
    font-weight: ${fontWeights.fontMedium};
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    margin: ${spacing.small} 0;
  }

  .TextLink {
    margin-top: ${spacing.regular};
    font-size: ${fontSizes.body};
    line-height: ${lineHeights.bodyXS};
    color: ${colors.solidBlack};
  }
  .TextLink:hover {
    &::after {
      transition: all 0.3s ease;
      transform: translateX(4px);
    }
  }
`;

export const SafetyClassIconWrapper = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: row;

  > div {
    margin-right: ${spacing.xxsmall};
  }
`;
