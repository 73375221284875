import Markdown from 'markdown-to-jsx';
import Image from 'next/image';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Paragraph } from '../paragraph/paragraph';
import { RouterLink } from '../RouterLink/RouterLink';
import { Section } from '../section/section';
import { SubscribeArea } from '../subscribe-area/subscribe-area';

import {
  BottomSection,
  Content,
  CookieSettings,
  FooterStyled,
  MiddleSection,
  SubscribeAreaWrapper,
} from './footer.styled';

export const Footer = () => {
  const { global, footer, settings } = useGlobal();

  const handleOnPressSettings = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  return (
    footer
    && global && (
      <FooterStyled>
        <Section>
          <Grid className="Grid">
            <GridChild>
              <div className="LogoWrapper">
                <img
                  src="/assets/gfx/solid-gear-logo-white.svg"
                  alt="Solid Gear logotype"
                  height="32px"
                  width="42px"
                />
              </div>

              <MiddleSection id="footer-middle-section">
                <div className="FooterFunctions">
                  {global.partnerPortalUrl && (
                    <Paragraph>
                      <a
                        href={global.partnerPortalUrl || ''}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="PartnerLoginLink"
                      >
                        {global.partnerPortalLabel}
                        <img
                          src="/assets/gfx/hultafors_partner_login_white.svg"
                          alt="login logo"
                          width="24px"
                          height="24px"
                        />
                      </a>
                    </Paragraph>
                  )}
                </div>
                {footer.hultaforsBrands && (
                  <Content>
                    <Paragraph>
                      {footer.hultaforsBrands.length > 0 && (
                        <>
                          <Markdown>{footer.introText || ''}</Markdown>
                          {': '}
                        </>
                      )}

                      {footer.hultaforsBrands.map((item, index) => {
                        return (
                          <span key={`Company-${index}`}>
                            <RouterLink
                              href={createUrl(item.url || '', settings) || ''}
                              dataValue={undefined}
                              id={undefined}
                              onClick={undefined}
                            >
                              {item.title}
                            </RouterLink>
                            {index < footer.hultaforsBrands.length - 1 && (
                              <>{' | '}</>
                            )}
                          </span>
                        );
                      })}
                    </Paragraph>
                    <Paragraph>
                      {footer.contactInfo}
                      {' '}
                      <RouterLink
                        className="Underline"
                        href={
                          createUrl(footer.contactUrl || '', settings) || ''
                        }
                        dataValue={undefined}
                        id={undefined}
                        onClick={undefined}
                      >
                        {footer.contactLinkText}
                      </RouterLink>
                    </Paragraph>
                  </Content>
                )}

                {footer.dotDigitalFormUrl && (
                  <SubscribeAreaWrapper>
                    <SubscribeArea
                      subscribeInfoText={footer.subscribeInfoText || ''}
                      subscribeButtonText={footer.subscribeButton || ''}
                      userEmailFormUrl={footer.dotDigitalFormUrl || ''}
                      userEmailFormImage={footer.dotDigitalImage}
                      closeLabel={global.close || ''}
                    />
                  </SubscribeAreaWrapper>
                )}
              </MiddleSection>
              <BottomSection>
                <Paragraph>
                  {footer.menu
                  && footer.menu.map((item, index) => {
                    return (
                      <span key={`Company-${index}`}>
                        <RouterLink
                          href={createUrl(item.url || '', settings) || ''}
                          dataValue={undefined}
                          id={undefined}
                          onClick={undefined}
                        >
                          {item.title}
                        </RouterLink>

                        {index < footer.menu.length - 1 && <>{' | '}</>}
                      </span>
                    );
                  })}
                  {'\u00A0|\u00A0'}
                  <CookieSettings onClick={handleOnPressSettings}>
                    {footer.cookieSettings}
                  </CookieSettings>
                </Paragraph>
                <div className="SocialMedia">
                  {footer.socialMediaMenu
                  && footer.socialMediaMenu.map((item, index) => {
                    if (item.url) {
                      return (
                        <a
                          key={`SocialMedia-${index}`}
                          href={item.url || ''}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={item.icon?.url || ''}
                            alt={item.icon?.alt || 'social media'}
                            width={40}
                            height={40}
                          />
                        </a>
                      );
                    }
                  })}
                </div>
              </BottomSection>
            </GridChild>
          </Grid>
        </Section>
      </FooterStyled>
    )
  );
};
