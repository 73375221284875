import {
  SafetyClassFragment,
  SafetyClassTableBlockFragment,
} from '@hultafors/solidgear/types';

import { Paragraph } from '../paragraph/paragraph';

import {
  ColumnTitle,
  Dot,
  DotsWrapper,
  SafetyClass,
  SafetyClassTableStyled,
  TableContainer,
  TableRow,
} from './SafetyClassTable.styled';

interface SafetyClassTableProps {
  safetyClassTable?: SafetyClassTableBlockFragment['classes'];
  includedSafetyClasses: SafetyClassFragment[];
  singleSafetyClass?: string;
}

export const SafetyClassTable: React.FC<SafetyClassTableProps> = ({
  safetyClassTable,
  includedSafetyClasses,
  singleSafetyClass,
}) => {
  return (
    <SafetyClassTableStyled>
      <TableContainer>
        <SafetyClass>
          {includedSafetyClasses.map((C, i) => (
            <ColumnTitle key={`Table-Column-${i}`} $color={C.color?.hex}>
              {C.name}
            </ColumnTitle>
          ))}
        </SafetyClass>
        {safetyClassTable?.map((row, i) => (
          <TableRow key={`Table-Row-${i}`}>
            <Paragraph>{row.title}</Paragraph>
            <DotsWrapper>
              {includedSafetyClasses.map((SC, j) => {
                const exists = Boolean(
                  row.safetyClasses.find((x) => x.name === SC.name),
                );
                const active = SC.name === singleSafetyClass ? true : false;
                return (
                  <Dot
                    $active={singleSafetyClass ? active : true}
                    $exists={exists}
                    $color={SC.color?.hex || ''}
                    key={`Table-Row-${j}`}
                  />
                );
              })}
            </DotsWrapper>
          </TableRow>
        ))}
      </TableContainer>
    </SafetyClassTableStyled>
  );
};
